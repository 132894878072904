import React, { Component } from "react";
import { connect } from "react-redux";
import "./Subscition.css";
import {
  fetchSingleSubscriptionStart,
  subscriptionPaymentStart,
} from "../../store/actions/SubscriptionAction";
import {
  fetchCardDetailsStart,
  selectDefaultCardStart,
} from "../../store/actions/CardsAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCardModel from "../Accounts/Cards/AddCardModel";
import { Link } from "react-router-dom";

const stripePromise = loadStripe("pk_test_uDYrTXzzAuGRwDYtu7dkhaF3");

class SubscriptionInvoice extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    if (this.props.location.state != null) {
      this.setState({ loading: false });
    } else {
      this.props.dispatch(
        fetchSingleSubscriptionStart({
          subscription_id: this.props.match.params.id,
        })
      );
    }
    if (this.props.cards.cardDetails.loading)
      this.props.dispatch(fetchCardDetailsStart());
  }
  handleSubmit = (event, subscription) => {
    this.props.dispatch(
      subscriptionPaymentStart({
        subscription_id: subscription.subscription_id,
      })
    );
  };

  handleCardChange = (event, card) => {
    this.props.dispatch(
      selectDefaultCardStart({ user_card_id: card.user_card_id })
    );
  };
  render() {
    let subscriptionData;
    if (this.props.location.state != null) {
      subscriptionData = this.props.location.state;
    } else {
      subscriptionData = this.props.subscriptions.singleSubscription.data;
    }

    const renderPayNow = (
      <div className="row">
        <div className="col-xl-7 col-md-12">
          <div className="card-body">
            <form className="invoice-form" autoComplete="off">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-16 text-primary t-font-boldest">Title</h3>
                  <span className="text-muted text-14 font-weight-600">
                    {subscriptionData.title}
                  </span>
                </div>
              </div>
              <div className="custom-separator"></div>
              <div className="row">
                <div className="col-xl-6 col-md-12 resp-mrg-btm-md">
                  <h3 className="text-16 text-primary t-font-boldest">
                    No of Users / Per Meeting
                  </h3>
                  <span className="text-muted text-14 font-weight-600">
                    {subscriptionData.no_of_users_formatted}
                  </span>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="invoice-box">
                    <h3 className="text-16 text-primary t-font-boldest">
                      No of Minutes / Per Meeting
                    </h3>
                    <span className="text-muted text-14 font-weight-600">
                      {subscriptionData.no_of_minutes_formatted}
                    </span>
                  </div>
                </div>
              </div>
              <div className="custom-separator"></div>

              <div className="row">
                {this.props.cards.cardDetails.loading ? (
                  "Getting Card Details..."
                ) : this.props.cards.cardDetails.data.cards.length > 0 ? (
                  <div className="col-xl-6 col-md-12 resp-mrg-btm-md">
                    <div className="form-row">
                      <div className="form-group mrg-btm-zero col-xl-6">
                        <h3 className="text-16 text-primary t-font-boldest">
                          Choose card
                        </h3>
                        <div className="ul-form__radio-inline">
                          {this.props.cards.cardDetails.data.cards.map(
                            (card) => (
                              <label className="ul-radio__position radio radio-primary form-check-inline">
                                <input
                                  type="radio"
                                  name="user_card_id"
                                  value={card.user_card_id}
                                  onChange={(event) =>
                                    this.handleCardChange(event, card)
                                  }
                                  defaultChecked={
                                    card.is_default == 1 ? true : false
                                  }
                                />
                                <span className="text-muted text-14 font-weight-600">
                                  XXXX.XXXX.XXXX.{card.last_four}
                                </span>
                                <span className="checkmark"></span>
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-xl-6 col-md-12 add-card-sec">
                    <div className="add-card">
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-toggle="modal"
                        data-target="#addCardModal"
                      >
                        Add Card
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="custom-separator"></div>
              {/*<div className="row col-md-12">
          <div className="align-left">
            <button
              type="submit"
              className="btn btn-primary btn-round"
              disabled={this.props.subscriptions.buttonDisable}
              onClick={(event) => this.handleSubmit(event, subscriptionData)}
            >
              {this.props.subscriptions.loadingButtonContent != null
                ? this.props.subscriptions.loadingButtonContent
                : "Pay Now"}
            </button>
          </div>
        </div>*/}
              <div className="row">
                <div className="col-xl-6">
                  <h3 className="text-16 text-primary t-font-boldest">Total</h3>
                  <span className="text-muted text-14 font-weight-600">
                    {subscriptionData.amount_formatted}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-5">
          <div className="invoice-img-sec">
            <img
              alt="subscription-invoice"
              src={window.location.origin + "/assets/images/invoice.svg"}
            />
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <div className="main-content subscribe-invoice">
          <div className="col-md-12 col-lg-12 mt-4 mb-4">
            <div className="card o-hidden h-100">
              <div className="card-header">
                <div className="">
                  <h4 className="text-18 font-weight-700">Invoice</h4>
                </div>
              </div>

              {this.props.subscriptions.singleSubscription.loading != true
                ? renderPayNow
                : null}
              {this.state.loading != true ? renderPayNow : null}

              <div>
                <div id="spark2"></div>
              </div>
              <div className="card-footer">
                <div className="mc-footer">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-xl-2 col-md-3">
                          <button
                            type="submit"
                            disabled={this.props.subscriptions.buttonDisable}
                            onClick={(event) =>
                              this.handleSubmit(event, subscriptionData)
                            }
                            className="btn btn-success btn-block m-1"
                            type="button"
                          >
                            {this.props.subscriptions.loadingButtonContent !=
                            null
                              ? this.props.subscriptions.loadingButtonContent
                              : "Pay Now"}
                          </button>
                        </div>
                        <div className="col-xl-2 col-md-3">
                          <Link
                            className="btn btn-outline-secondary btn-block m-1"
                            to={"/my-subscriptions"}
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Elements stripe={stripePromise}>
          <AddCardModel />
        </Elements>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
  cards: state.cards,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionInvoice);
