import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchDashboardStart } from "../../store/actions/UserAction";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import DashboardLoader from "../Loader/DashboardLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const DashboardIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchDashboardStart());
  }, []);

  const dashboard = props.dashboard;

  function analytics() {
    // let last_x_days_meetings_website =
    //   props.dashboard.data.last_x_days_meetings_website;
    // var a = ["x", "meetings"];
    // var c = [...["x", "meetings"], ...last_x_days_meetings_website];
    // console.log("Fact", c);
  }

  return (
    <div className="main-content dashboard-sec">
      <div className="breadcrumb">
        <h1 className="mr-2">Dashboard</h1>
      </div>
      {dashboard.loading ? (
        <DashboardLoader />
      ) : (
        <>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card card-icon mb-4">
                    <div className="card-body text-center">
                      <i className="i-Clock"></i>
                      <p className="text-muted mt-2 mb-2">Today's Scheduled</p>
                      <p className="text-primary text-24 line-height-1 m-0">
                        {dashboard.data.today_scheduled_meetings}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card card-icon mb-4">
                    <div className="card-body text-center">
                      <i className="i-Dashboard"></i>
                      <p className="text-muted mt-2 mb-2">Completed Meetings</p>
                      <p className="text-primary text-24 line-height-1 m-0">
                        {dashboard.data.total_completed_meetings}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card card-icon mb-4">
                    <div className="card-body text-center">
                      <i className="i-Data-Yes"></i>
                      <p className="text-muted mt-2 mb-2">Total Meetings</p>
                      <p className="text-primary text-24 line-height-1 m-0">
                        {dashboard.data.total_meetings}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card card-icon mb-4">
                    <div className="card-body text-center">
                      <i className="i-Book"></i>
                      <p className="text-muted mt-2 mb-2">Today Meetings</p>
                      <p className="text-primary text-24 line-height-1 m-0">
                        {dashboard.data.total_today_meetings}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="card mb-4" style={{ minHeight: "23rem" }}>
                <div className="card-body">
                  <h5 className="card-title">Subscription Information</h5>
                  {dashboard.data.current_subscription ? (
                    <>
                      <p>
                        You have subscribed{" "}
                        {dashboard.data.current_subscription.subscription_name}.{" "}
                        <Link to="/my-subscriptions">
                          Click Here for More Info
                        </Link>
                      </p>
                      <div class="ul-widget1">
                        <div class="ul-widget__item">
                          <div class="ul-widget__info">
                            <h3 class="ul-widget1__title">No of Minutes</h3>
                            <span class="ul-widget__desc text-mute">
                              Allowed Per Meeting
                            </span>
                          </div>
                          <span class="ul-widget__number text-primary">
                            {dashboard.data.current_subscription.no_of_minutes}
                          </span>
                        </div>
                        <div class="ul-widget__item">
                          <div class="ul-widget__info">
                            <h3 class="ul-widget1__title">No Of users</h3>
                            <span class="ul-widget__desc text-mute">
                              Allowed Per Meeting
                            </span>
                          </div>
                          <span class="ul-widget__number text-danger">
                            {dashboard.data.current_subscription.no_of_users}
                          </span>
                        </div>
                        <div class="ul-widget__item">
                          <div class="ul-widget__info">
                            <h3 class="ul-widget1__title">Expiry Date</h3>
                            <span class="ul-widget__desc text-mute">
                              Auto renewal{" "}
                              {dashboard.data.current_subscription
                                .is_autorenewal
                                ? "Enabled"
                                : "Cancelled"}
                            </span>
                          </div>
                          <span class="ul-widget__number text-success">
                            {dashboard.data.current_subscription.expiry_date}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex center">
                      <p className="text-muted">
                        You haven't subscribed any plan yet. Please click the
                        below button to upgrade your plan.
                      </p>
                      <Link
                        to={"/my-subscriptions"}
                        className="btn btn-primary "
                      >
                        Upgrade Plan
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Recent Meetings</h5>

                  {dashboard.data.recent_meetings.length > 0 ? (
                    dashboard.data.recent_meetings.map((meeting) => (
                      <div
                        key={meeting.meeting_unique_id}
                        className="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3"
                      >
                        <img
                          className="avatar-md rounded mr-3"
                          src={meeting.picture}
                          alt={meeting.meeting_unique_id}
                        />
                        <div className="flex-grow-1">
                          <h6 className="m-0">{meeting.title}</h6>
                          <p className="m-0 text-small text-muted">
                            {meeting.schedule_time}
                          </p>
                        </div>
                        <div>
                          <Link
                            to={`/meeting/${meeting.meeting_unique_id}`}
                            className="btn btn-outline-primary btn-rounded btn-sm"
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </div>
                {dashboard.data.recent_meetings.length > 0 ? (
                  <div className="card-footer">
                    <Link
                      to={`/meetings`}
                      className="btn btn-primary btn-block"
                    >
                      More
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="card mb-4">
                <div className="card-body p-0">
                  <h5 className="card-title m-0 p-3">Statistics</h5>
                  <Chart
                    width={"600px"}
                    height={"300px"}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    // data={[
                    //   ["x", "meetings"],
                    //   ["2020-09-03", 2],
                    //   ["2020-09-04", 0],
                    //   ["2020-09-05", 1],
                    //   ["2020-09-06", 4],
                    //   ["2020-09-07", 2],
                    //   ["2020-09-03", 2],
                    //   ["2020-09-04", 0],
                    //   ["2020-09-05", 1],
                    //   ["2020-09-06", 4],
                    //   ["2020-09-07", 2],
                    //   ["2020-09-03", 2],
                    //   ["2020-09-04", 0],
                    //   ["2020-09-05", 1],
                    //   ["2020-09-06", 4],
                    //   ["2020-09-07", 2],
                    // ]}
                    // data={[
                    //   ["x", "meetings"],
                    //   dashboard.data.last_x_days_meetings_website,
                    // ]}
                    data={dashboard.chartData}
                    options={{
                      hAxis: {
                        title: "Date",
                      },
                      vAxis: {
                        title: "Total Meeting",
                      },
                    }}
                    rootProps={{ "data-testid": "1" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="separator-breadcrumb border-top"></div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  dashboard: state.users.dashboard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DashboardIndex);
