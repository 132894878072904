import React, { Component } from "react";
import SingleMeetingUserList from "./SingleMeetingUserList";
import { connect } from "react-redux";
import { fetchSingleMeetingStart } from "../../../store/actions/MeetingAction";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import AddToCalendar from "react-add-to-calendar";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { Link } from "react-router-dom";
import SingleMeetingLoader from "../../Loader/SingleMeetingLoader";
import MeetingInfoModal from "../MeetingInfoModal";
let icon = { "calendar-plus-o": "left" };

class SingleMeetingIndex extends Component {
  state = {
    userDisplay: "none",
    modalData: {},
    modelLoading: true,
    shareData: "",
    modalShow: false,
  };
  componentDidMount() {
    this.props.dispatch(
      fetchSingleMeetingStart({ meeting_unique_id: this.props.match.params.id })
    );
  }

  changeDisplayStatus = (status) => {
    if (status == "view") this.setState({ userDisplay: "block" });
    if (status == "none") this.setState({ userDisplay: "none" });
  };

  onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Meeting Link Copied!!"
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  changeModelData = (event, meeting) => {
    this.setState({
      modelLoading: false,
      modalData: meeting,
      shareData: `Meeting ID: ${meeting.meeting_unique_id}\nTitle: ${meeting.title}\nDescription: ${meeting.description}\nScheduled: ${meeting.schedule_time}\nMeeting Link: ${window.location.origin}/join/${meeting.meeting_unique_id}`,
      modalShow: true,
    });
  };

  render() {
    const { singleMeeting } = this.props;
    return (
      <div className="main-content single-meeting">
        <section className="widgets-content">
          <div className="row mt-4">
            <div
              className={
                this.state.userDisplay == "none"
                  ? "display-column-lg"
                  : "display-column-lg display-column-md resp-mrg-btm-md"
              }
            >
              <div className="card">
                <div className="card-header flex-content bg-transparent">
                  <h3>Meeting Information</h3>
                  <Link className="btn btn-primary btn-lg m-1" to={"/meetings"}>
                    <i className="fas fa-arrow-left mr-2"></i> Back
                  </Link>
                </div>
                {singleMeeting.loading ? (
                  <SingleMeetingLoader />
                ) : (
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div class="card">
                            <div class="card-body">
                              <img
                                class="d-block w-100 rounded"
                                src={singleMeeting.data.meeting_details.picture}
                                alt={singleMeeting.data.meeting_details.title}
                              />
                              <h5 class="card-title mt-4 mb-2">
                                {singleMeeting.data.meeting_details.title}
                              </h5>
                              <p class="card-text text-mute">
                                {
                                  singleMeeting.data.meeting_details
                                    .schedule_time
                                }
                              </p>
                              <div class="ul-widget-card__info mb-2">
                                <span>
                                  <p>
                                    {
                                      singleMeeting.data.meeting_details
                                        .no_of_users_formatted
                                    }
                                  </p>
                                  <p class="text-mute">Allowed</p>
                                </span>
                                <span>
                                  <p>
                                    {
                                      singleMeeting.data.meeting_details
                                        .no_of_minutes_formatted
                                    }
                                  </p>
                                  <p class="text-mute m-0">Allowed</p>
                                </span>
                              </div>

                              <div className="ul-widget-card__info">
                                {singleMeeting.data.meeting_details
                                  .overall_record_url ? (
                                  <a
                                    href={
                                      singleMeeting.data.meeting_details
                                        .overall_record_url
                                    }
                                    download={`overall-${singleMeeting.data.meeting_details.meeting_unique_id}`}
                                    target="_blank"
                                    className="btn btn-outline-primary btn-lg width-300 m-1"
                                  >
                                    <i className="i-Download"></i> Download
                                    Overall Video
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="ul-widget-card__info">
                                {singleMeeting.data.meeting_details
                                  .video_record_url ? (
                                  <a
                                    href={
                                      singleMeeting.data.meeting_details
                                        .video_record_url
                                    }
                                    download={`webcam-${singleMeeting.data.meeting_details.meeting_unique_id}`}
                                    target="_blank"
                                    // className="btn btn-outline-primary"
                                    className="btn btn-outline-primary btn-lg width-300 m-1"
                                  >
                                    <i className="i-Download"></i> Download
                                    WebCam
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>

                              {singleMeeting.data.meeting_details
                                .start_btn_status == 1 ? (
                                <>
                                  <hr></hr>
                                  <Link
                                    to={`/join/${singleMeeting.data.meeting_details.meeting_unique_id}`}
                                    target="_blank"
                                    className="btn btn-primary btn-lg width-200 btn-md m-1"
                                    type="button"
                                  >
                                    Start Meeting
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}

                              {singleMeeting.data.meeting_details
                                .joing_btn_status == 1 ? (
                                <>
                                  <hr></hr>
                                  <Link
                                    to={`/join/${singleMeeting.data.meeting_details.meeting_unique_id}`}
                                    target="_blank"
                                    className="btn btn-primary btn-lg width-200 btn-md m-1"
                                    type="button"
                                  >
                                    {" "}
                                    Join Meeting
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}

                              {/* <hr></hr> */}
                              {/* {this.state.userDisplay != "none" ? (
                                <a
                                  href="#"
                                  className="btn btn-primary view-user"
                                  onClick={() =>
                                    this.changeDisplayStatus("none")
                                  }
                                >
                                  Close User
                                </a>
                              ) : (
                                <a
                                  href="#"
                                  className="btn btn-primary view-user"
                                  onClick={() =>
                                    this.changeDisplayStatus("view")
                                  }
                                >
                                  View User
                                </a>
                              )} */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="card">
                            <div className="card-header flex-content bg-transparent">
                              <h4>Information</h4>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        Meeting Id
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <h5>
                                        {
                                          singleMeeting.data.meeting_details
                                            .meeting_unique_id
                                        }
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="custom-separator"></div>
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        Scheduled At:
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <h5>
                                        {singleMeeting.data.meeting_details
                                          .schedule_time
                                          ? singleMeeting.data.meeting_details
                                              .schedule_time
                                          : "-"}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="custom-separator"></div>
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        Calendar
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <AddToCalendar
                                        event={{
                                          title:
                                            singleMeeting.data.meeting_details
                                              .title,
                                          description:
                                            singleMeeting.data.meeting_details
                                              .description,
                                          startTime:
                                            singleMeeting.data.meeting_details
                                              .schedule_time,
                                          location: "",
                                        }}
                                        buttonTemplate={icon}
                                      />
                                    </div>
                                  </div>
                                  <div className="custom-separator"></div>
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        Start Date
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <h5>
                                        {singleMeeting.data.meeting_details
                                          .start_time
                                          ? singleMeeting.data.meeting_details
                                              .start_time
                                          : "-"}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="custom-separator"></div>
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        End Date
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <h5>
                                        {singleMeeting.data.meeting_details
                                          .end_time
                                          ? singleMeeting.data.meeting_details
                                              .end_time
                                          : "-"}
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="custom-separator"></div>
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        Share Link
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <p>
                                        <a href="javascript:void(0);">
                                          {window.location.origin}/join/
                                          {
                                            singleMeeting.data.meeting_details
                                              .meeting_unique_id
                                          }
                                        </a>
                                      </p>
                                      <p>
                                        <button
                                          className="ul-widget5__desc green-link"
                                          data-toggle="modal"
                                          data-target="#meetingInfoModal"
                                          onClick={(event) =>
                                            this.changeModelData(
                                              event,
                                              singleMeeting.data.meeting_details
                                            )
                                          }
                                        >
                                          <i className="far fa-copy mr-2"></i>
                                          Copy to clipboard
                                        </button>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="custom-separator"></div>
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        Status
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <h5>
                                        {
                                          singleMeeting.data.meeting_details
                                            .status_formatted
                                        }
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="custom-separator"></div>
                                  <div className="single-meeting-info-details-content">
                                    <div className="left-align-single-meeting-info">
                                      <h5 className="heading text-muted">
                                        Description
                                      </h5>
                                    </div>
                                    <div className="right-align-single-meeting-info">
                                      <h5>
                                        {
                                          singleMeeting.data.meeting_details
                                            .description
                                        }
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="video-sec">
                        <video width="700" height="400" controls>
                          <source
                            src="https://www.youtube.com/watch?v=BC56rj2ZQSY"
                            type="video/mp4"
                          />
                        </video>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <SingleMeetingUserList userDisplay={this.state.userDisplay} />

            <MeetingInfoModal
              meeting={this.state.modalData}
              loading={this.state.modelLoading}
              shareData={this.state.shareData}
              closeModel={this.closeModel}
              modalShow={this.state.modalShow}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  singleMeeting: state.meeting.singleMeeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleMeetingIndex);
