import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./MyPlanNew.css";
class MyPlanNew extends Component {
  render() {
    return (
      <>
        <div className="my-plan-new-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link pl-0 active"
                      id="pills-current-plan-tab"
                      data-toggle="pill"
                      href="#pills-current-plan"
                      role="tab"
                      aria-controls="pills-current-plan"
                      aria-selected="true"
                    >
                      Current Plans
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="pills-pending-plan-tab"
                      data-toggle="pill"
                      href="#pills-pending-plan"
                      role="tab"
                      aria-controls="pills-pending-plan"
                      aria-selected="false"
                    >
                      Pending Plans
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="pills-billing-info-tab"
                      data-toggle="pill"
                      href="#pills-billing-info"
                      role="tab"
                      aria-controls="pills-billing-info"
                      aria-selected="false"
                    >
                      Billing Information
                    </a>
                  </li>

                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="pills-invoice-history-tab"
                      data-toggle="pill"
                      href="#pills-invoice-history"
                      role="tab"
                      aria-controls="pills-invoice-history"
                      aria-selected="false"
                    >
                      Invoice History
                    </a>
                  </li>
                  <li class="nav-item ml-auto">
                    <a class="nav-link pr-0">
                      <span className="color-purple">Help</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-current-plan"
                    role="tabpanel"
                    aria-labelledby="pills-current-plan-tab"
                  >
                    <h2>Current Plans</h2>
                    <div className="row">
                      <div className="col-md-12">
                        <table class="table my-plans-new">
                          <thead>
                            <tr>
                              <th scope="col">Plan Name</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Details</th>
                              <th scope="col">Billing Period</th>
                              <th scope="col">Service Effective Date</th>
                              <th scope="col">Next Invoice Date</th>
                              <th scope="col">Next Invoice Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="btn-footer-section">
                          <button
                            type="button"
                            className="btn btn-bottom active"
                          >
                            Upgrade plan
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Update Credit Card
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Pay Bill/ View Invoice
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-pending-plan"
                    role="tabpanel"
                    aria-labelledby="pills-pending-plan-tab"
                  >
                    <h2>Pending Plans</h2>
                    <div className="row">
                      <div className="col-md-12">
                        <table class="table my-plans-new">
                          <thead>
                            <tr>
                              <th scope="col">Plan Name</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Details</th>
                              <th scope="col">Billing Period</th>
                              <th scope="col">Service Effective Date</th>
                              <th scope="col">Next Invoice Date</th>
                              <th scope="col">Next Invoice Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="btn-footer-section">
                          <button
                            type="button"
                            className="btn btn-bottom active"
                          >
                            Upgrade plan
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Update Credit Card
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Pay Bill/ View Invoice
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-billing-info"
                    role="tabpanel"
                    aria-labelledby="pills-billing-info-tab"
                  >
                    <h2>Billing Information</h2>
                    <div className="row">
                      <div className="col-md-12">
                        <table class="table my-plans-new">
                          <thead>
                            <tr>
                              <th scope="col">Plan Name</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Details</th>
                              <th scope="col">Billing Period</th>
                              <th scope="col">Service Effective Date</th>
                              <th scope="col">Next Invoice Date</th>
                              <th scope="col">Next Invoice Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="btn-footer-section">
                          <button
                            type="button"
                            className="btn btn-bottom active"
                          >
                            Upgrade plan
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Update Credit Card
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Pay Bill/ View Invoice
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-invoice-history"
                    role="tabpanel"
                    aria-labelledby="pills-invoice-history-tab"
                  >
                    <h2>Invoice History</h2>
                    <div className="row">
                      <div className="col-md-12">
                        <table class="table my-plans-new">
                          <thead>
                            <tr>
                              <th scope="col">Plan Name</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Details</th>
                              <th scope="col">Billing Period</th>
                              <th scope="col">Service Effective Date</th>
                              <th scope="col">Next Invoice Date</th>
                              <th scope="col">Next Invoice Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">Pro</td>
                              <td>1 host</td>
                              <td>100 participants</td>
                              <td>Montly</td>
                              <td>Sep 8,2020</td>
                              <td>Oct 8, 2020</td>
                              <td>$14.99</td>
                              <td>Active</td>
                              <td className="color-orange">
                                Add/Edit Cancel Subscription
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="btn-footer-section">
                          <button
                            type="button"
                            className="btn btn-bottom active"
                          >
                            Upgrade plan
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Update Credit Card
                          </button>
                          <button type="button" className="btn btn-bottom">
                            Pay Bill/ View Invoice
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MyPlanNew;
