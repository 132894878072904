import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteRecordingStart,
  fetchRecordingStart,
} from "../../store/actions/RecordingAction";
import NoDataFound from "../NoDataFound/NoDataFound";

class RecordingListIndex extends Component {
  state = {};

  componentDidMount() {
    if (this.props.recording.recording.loading)
      this.props.dispatch(fetchRecordingStart());
  }

  deleteRecording = (event, recording) => {
    event.preventDefault();
    this.props.dispatch(
      deleteRecordingStart({ meeting_record_id: recording.meeting_record_id })
    );
  };

  render() {
    const { recording } = this.props.recording;
    return (
      <section className="widgets-content">
        <div className="row mt-4">
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="card">
              <div className="card-header flex-content bg-transparent">
                <h4>Recording Management</h4>
              </div>
              <div className="card-body row">
                {recording.loading ? (
                  "Loading..."
                ) : recording.data.records.length > 0 ? (
                  recording.data.records.map((meetingRecord) => (
                    <div className="ul-widget__body col-md-6">
                      <div className="ul-widget5">
                        <div className="ul-widget5__item section-display">
                          <div className="ul-widget5__content">
                            <div className="ul-widget5__pic">
                              <img
                                src={meetingRecord.meeting_details.picture}
                                alt={meetingRecord.meeting_record_unique_id}
                              />
                            </div>
                            <div className="ul-widget5__section resp-padding-top ">
                              <Link
                                className="ul-widget4__title"
                                to={`/recording/${meetingRecord.meeting_record_unique_id}`}
                              >
                                {meetingRecord.meeting_details.title}
                              </Link>
                              <p className="ul-widget5__desc font-8">
                                <span>Scheduled: </span>
                                <span className="">
                                  {meetingRecord.meeting_details.schedule_time}
                                </span>
                              </p>
                              <div className="ul-widget5__info">
                                <span>Allowed: </span>
                                <span className="text-primary">
                                  {" "}
                                  {
                                    meetingRecord.meeting_details
                                      .no_of_users_formatted
                                  }
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="ul-widget5__content">
                            <button
                              className="btn bg-white _r_btn border-0"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                className="action-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/list.svg"
                                }
                                alt="List"
                              />
                            </button>
                            <div
                              className="dropdown-menu"
                              x-placement="bottom-start"
                              style={{
                                position: "absolute",
                                transform: "translate3d(0px, 33px, 0px)",
                                top: "0px",
                                left: "0px",
                                willChange: "transform",
                              }}
                            >
                              {meetingRecord.is_owner == 1 ? (
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(event) =>
                                    this.deleteRecording(event, meetingRecord)
                                  }
                                >
                                  Delete
                                </a>
                              ) : (
                                ""
                              )}
                              <Link
                                className="dropdown-item"
                                to={`/recording/${meetingRecord.meeting_record_unique_id}`}
                                target="_blank"
                              >
                                View
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToPros = (state) => ({
  recording: state.recording,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RecordingListIndex);
