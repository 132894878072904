import React, { useState, useEffect } from "react";
import AddCardModel from "./AddCardModel";
import { connect } from "react-redux";
import {
  fetchCardDetailsStart,
  selectDefaultCardStart,
  deleteCardStart,
} from "../../../store/actions/CardsAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCardLoader from "../../Loader/AddCardLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import configuration from "react-global-configuration";


const CardsList = (props) => {

  const stripePromise = loadStripe(configuration.get("configData.stripe_publishable_key"));

  useEffect(() => {
    props.dispatch(fetchCardDetailsStart());
  }, []);

  const changeDefaultCard = (event, card) => {
    event.preventDefault();
    props.dispatch(
      selectDefaultCardStart({ user_card_id: card.user_card_id })
    );
  };

  const deleteCard = (event, card) => {
    event.preventDefault();
    props.dispatch(deleteCardStart({ user_card_id: card.user_card_id }));
  };

  const { cardDetails } = props.cards;
  console.log(cardDetails);
  return (
    <div className="card">
      <div className="card-header bg-transparent">
        <h4>Payments</h4>
      </div>
      <div className="card-body">
        <div className="row">
          {cardDetails.loading ? (
            <AddCardLoader />
          ) : cardDetails.data.cards.length > 0 ? (
            cardDetails.data.cards.map((card) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 card-margin-btm">
                <div className="card card-list-sec">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-10 col-md-9">
                        <p className="text-small text-muted">
                          XXXX XXXX XXXX {card.last_four}
                        </p>
                        <p className="text-muted">{card.card_holder_name}</p>
                        {card.is_default == 1 ? (
                          <a
                            className="card-link text-danger"
                            href="#"
                            onClick={(event) =>
                              changeDefaultCard(event, card)
                            }
                          >
                            Remove Default
                          </a>
                        ) : (
                          <a
                            className="card-link"
                            href="#"
                            onClick={(event) =>
                              changeDefaultCard(event, card)
                            }
                          >
                            Set as Default
                          </a>
                        )}
                      </div>
                      {card.is_default !== 1 ? (
                        <div className="col-lg-2 col-md-3 align-right-lg">
                          <div className="card-icon-right">
                            <i className="i-Credit-Card-2"></i>
                            <a
                              href="#"
                              onClick={(event) =>
                                deleteCard(event, card)
                              }
                            >
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/images/remove-icon.png"
                                }
                                alt="remove-icon"
                                className="remove-icon-card"
                              />
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoDataFound />
          )}
        </div>
        <div className="tab-content" id="myPillTabContent">
          <div
            className="tab-pane fade show active"
            id="homePIll"
            role="tabpanel"
            aria-labelledby="home-icon-pill"
          >
            <button
              className="btn btn-primary m-1"
              type="button"
              data-toggle="modal"
              data-target="#addCardModal"
            >
              Add Card
            </button>
          </div>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <AddCardModel />
      </Elements>
    </div>
  );
}

const mapStateToPros = (state) => ({
  cards: state.cards,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CardsList);
