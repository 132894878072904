import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ProgressComponent from "@material-ui/core/CircularProgress";
import {
  endMeetingJitsiStart,
  fetchSingleMeetingStart,
} from "../../store/actions/MeetingAction";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";

function JitsiMeetingCreate(props) {
  const [loading, setLoading] = useState(false);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const jitsiContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  function startConference() {
    try {
      const domain = "jitsi-streaming.startstreaming.co";
      const options = {
        roomName: props.match.params.meeting_unique_id,
        height: 400,
        parentNode: document.getElementById("jitsi-container"),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
        },
        configOverwrite: {
          disableSimulcast: false,
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      api.addEventListener("videoConferenceJoined", () => {
        console.log("Local User Joined");
        setLoading(false);
        api.executeCommand("displayName", localStorage.getItem("username"));
      });
      api.addEventListener("readyToClose", () => {
        endConference();
      });
    } catch (error) {
      console.error("Failed to load Jitsi API", error);
    }
  }

  const endConference = () => {
    props.dispatch(
      endMeetingJitsiStart({
        meeting_unique_id: props.match.params.meeting_unique_id,
      })
    );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Meeting Link Copied!!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else alert("Jitsi Meet API script not loaded");

    props.dispatch(
      fetchSingleMeetingStart({
        meeting_unique_id: props.match.params.meeting_unique_id,
      })
    );
  }, []);

  return (
    <div className="jitsi-card">
      {props.singleMeeting.loading ? (
        ""
      ) : (
        <div className="jitsi-header">
          <div className="header-info">
            <h4 className="title">
              {props.singleMeeting.data.meeting_details.title}
            </h4>
            <p className="time">
              {props.singleMeeting.data.meeting_details.meeting_unique_id} |{" "}
              {props.singleMeeting.data.meeting_details.schedule_time_formatted}
            </p>
            <p className="desc">
              {props.singleMeeting.data.meeting_details.description}
            </p>
          </div>
          <div className="header-copy-url">
            <CopyToClipboard
              text={`Meeting ID: ${props.singleMeeting.data.meeting_details.meeting_unique_id}\nTitle: ${props.singleMeeting.data.meeting_details.title}\nDescription: ${props.singleMeeting.data.meeting_details.description}\nScheduled: ${props.singleMeeting.data.meeting_details.schedule_time}\nMeeting Link: ${window.location.origin}/join/${props.singleMeeting.data.meeting_details.meeting_unique_id}`}
              onCopy={onCopy}
            >
              <button className="btn btn-outline-primary btn-rounded btn-lg">
                <i class="fas fa-clone mr-2"></i> Copy to clipboard
              </button>
            </CopyToClipboard>
          </div>
        </div>
      )}

      <div style={containerStyle}>
        {loading && <ProgressComponent />}
        <div id="jitsi-container" style={jitsiContainerStyle} />
      </div>
    </div>
  );
}

const mapStateToPros = (state) => ({
  meeting: state.meeting,
  singleMeeting: state.meeting.singleMeeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(JitsiMeetingCreate);
