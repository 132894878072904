import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchSubscriptionStart } from "../../store/actions/SubscriptionAction";
import SubscriptionsNewLoader from "../Loader/SubscriptionsNewLoader";
import NoResultFound from "../NoResultFound/NoResultFound";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class SubscriptionIndex extends Component {
  state = {};
  componentDidMount() {
    if (this.props.subscriptions.subscription.loading)
      this.props.dispatch(fetchSubscriptionStart());
  }
  handleSubmit = (event, subscription) => {
    event.preventDefault();
    this.props.history.push(
      `/subscriptions-invoice/${subscription.subscription_id}`,
      subscription
    );
  };
  sendSubscriptionHistory = (event) => {
    event.preventDefault();
    this.props.history.push("/my-subscriptions");
  };
  render() {
    const { subscription } = this.props.subscriptions;
    return (
      <div className="subscription-latest-sec">
        <div className="container">
          {subscription.loading ? (
            <SubscriptionsNewLoader />
          ) : (
            <div className="subscription-latest-list-sec">
              <h3 className="title-main">Upgrade Your Plan</h3>
              <p className="desc-main">
                {subscription.data.current_subscription ? (
                  <span>
                    You are currently on a{" "}
                    {subscription.data.current_subscription.title}. Select a
                    plan below to Upgrade your{" "}
                    {configuration.get("configData.site_name")} account.
                  </span>
                ) : (
                  <span>
                    You are currently not subscribed any plan. Select a plan
                    below to Upgrade your{" "}
                    {configuration.get("configData.site_name")} account.
                  </span>
                )}
              </p>
              <div className="row no-gutters">
                {subscription.data.subscriptions.length > 0 ? (
                  subscription.data.subscriptions.map((value, index) => (
                    <div className="col-lg-3 col-xl-3 resp-mrg-btm-xs">
                      <>
                        {value.is_popular ? (
                          <div className="header-content active">
                            <h6>Recommended Plan</h6>
                          </div>
                        ) : (
                          <div className="header-content">
                            <h6></h6>
                          </div>
                        )}
                        <div className="price-box">
                          <div className="price-header">
                            <h4>{value.title}</h4>
                            <h6 className="text-muted">{value.title}</h6>
                            <h2>
                              <span>
                                {value.amount_formatted}
                                <span className="sub-desc">
                                  /{value.plan_formatted}
                                </span>
                              </span>
                              {/* <span className="sub-desc-icon">
                                <i class="fas fa-info-circle"></i>
                              </span> */}
                            </h2>
                            {value.is_popular ? (
                              <button
                                className="btn price-btn btn-block active"
                                onClick={(event) =>
                                  this.handleSubmit(event, value)
                                }
                              >
                                Upgrade Plan
                              </button>
                            ) : (
                              <button
                                className="btn price-btn btn-block active-2"
                                onClick={(event) =>
                                  this.handleSubmit(event, value)
                                }
                              >
                                Upgrade Plan
                              </button>
                            )}
                          </div>
                          <div className="price-body">
                            <ul className="price-list-sec list-unstyled">
                              <li>
                                <span>
                                  Host up to {value.no_of_users} participants
                                </span>
                                <i class="fas fa-info-circle color-grey"></i>
                              </li>
                              {/* <li>
                                <span>Unlimited 1 to 1 and group meetings</span>
                              </li> */}
                              <li>
                                <span>
                                  {value.no_of_minutes_formatted} limit on group
                                  meetings
                                </span>
                                <i class="fas fa-info-circle color-grey"></i>
                              </li>
                            </ul>
                            <hr></hr>
                            <p>{value.description}</p>
                          </div>
                        </div>
                      </>
                    </div>
                  ))
                ) : (
                  <NoResultFound />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionIndex);
