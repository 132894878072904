import React, { Component } from "react";
import SingleMeetingUserList from "../../Meeting/SingleMeeting/SingleMeetingUserList";
import { connect } from "react-redux";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import AddToCalendar from "react-add-to-calendar";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { Link } from "react-router-dom";
import SingleMeetingLoader from "../../Loader/SingleMeetingLoader";
import MeetingInfoModal from "../../Meeting/MeetingInfoModal";
import { fetchSingleRecordingStart } from "../../../store/actions/RecordingAction";
import Iframe from "react-iframe";
let icon = { "calendar-plus-o": "left" };

class SingleRecodingIndex extends Component {
  state = {
    userDisplay: "none",
    modalData: {},
    modelLoading: true,
    shareData: "",
    modalShow: false,
  };
  componentDidMount() {
    this.props.dispatch(
      fetchSingleRecordingStart({
        meeting_record_unique_id: this.props.match.params.id,
      })
    );
  }

  changeDisplayStatus = (status) => {
    if (status == "view") this.setState({ userDisplay: "block" });
    if (status == "none") this.setState({ userDisplay: "none" });
  };

  onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Meeting Link Copied!!"
    );
    this.props.dispatch(createNotification(notificationMessage));
  };

  changeModelData = (event, meeting) => {
    this.setState({
      modelLoading: false,
      modalData: meeting,
      shareData: `Meeting ID: ${meeting.meeting_unique_id}\nTitle: ${meeting.title}\nDescription: ${meeting.description}\nMeeting Link: ${window.location.origin}/join/${meeting.meeting_unique_id}`,
      modalShow: true,
    });
  };

  render() {
    const { singleRecording } = this.props;
    return (
      <div className="main-content single-meeting">
        <section className="widgets-content">
          <div className="row mt-4">
            <div
              className={
                this.state.userDisplay == "none"
                  ? "display-column-lg"
                  : "display-column-lg display-column-md resp-mrg-btm-md"
              }
            >
              <div className="card resp-box-shadow-none">
                <div className="card-header flex-content bg-transparent">
                  <h3>Meeting Record Information</h3>
                  <Link className="btn btn-primary btn-lg m-1" to={"/meetings"}>
                    <i className="fas fa-arrow-left mr-2"></i> Back
                  </Link>
                </div>
                {singleRecording.loading ? (
                  <singleRecordingLoader />
                ) : (
                  <div className="col-lg-12 col-xl-12 mb-3">
                    <div className="card-body resp-padding-rec">
                      <div className="row">
                        <div className="col-md-4 resp-mrg-btm-sm">
                          <div class="card">
                            <div class="card-body">
                              {/* <Iframe
                                url={
                                  singleRecording.data.meeting_details
                                    .recording_url
                                }
                                width="450px"
                                height="450px"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                              /> */}

                              <img
                                class="d-block w-100 rounded"
                                src={
                                  singleRecording.data.meeting_details.picture
                                }
                                alt={singleRecording.data.meeting_details.title}
                              />
                              <h5 class="card-title mt-4 mb-2">
                                {singleRecording.data.meeting_details.title}
                              </h5>
                              <p class="card-text text-mute">
                                {
                                  singleRecording.data.meeting_details
                                    .schedule_time
                                }
                              </p>
                              <div class="ul-widget-card__info">
                                <span>
                                  <p>
                                    {
                                      singleRecording.data.meeting_details
                                        .no_of_users_formatted
                                    }
                                  </p>
                                  <p class="text-mute">Allowed</p>
                                </span>
                                <span>
                                  <p>
                                    {
                                      singleRecording.data.meeting_details
                                        .no_of_minutes_formatted
                                    }
                                  </p>
                                  <p class="text-mute m-0">Allowed</p>
                                </span>
                              </div>

                              <div class="ul-widget-card__info">
                                <Link
                                  to={`/join/${singleRecording.data.meeting_details.meeting_unique_id}`}
                                  target="_blank"
                                  className="btn btn-primary mt-2"
                                  type="button"
                                >
                                  View Meeting
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-header flex-content bg-transparent">
                                <h4>Information</h4>
                              </div>
                              <div className="card-body">
                                <div className="col-md-12">
                                  {singleRecording.data.record_details
                                    .overall_record_url ? (
                                    <a
                                      href={
                                        singleRecording.data.record_details
                                          .overall_record_url
                                      }
                                      download={`overall-${singleRecording.data.meeting_details.meeting_unique_id}`}
                                      target="_blank"
                                      className="btn btn-outline-primary mr-5"
                                    >
                                      <i className="i-Download"></i> Download
                                      Overall Video
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  {singleRecording.data.record_details
                                    .video_record_url ? (
                                    <a
                                      href={
                                        singleRecording.data.record_details
                                          .video_record_url
                                      }
                                      download={`webcam-${singleRecording.data.meeting_details.meeting_unique_id}`}
                                      target="_blank"
                                      className="btn btn-outline-primary"
                                    >
                                      <i className="i-Download"></i> Download
                                      WebCam
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <hr></hr>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Iframe
                                      url={
                                        singleRecording.data.meeting_details
                                          .recording_url
                                      }
                                      id="myId"
                                      className="myClassname iframe-size"
                                      display="block"
                                      position="relative"
                                      overflow="auto"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <SingleMeetingUserList userDisplay={this.state.userDisplay} />

            <MeetingInfoModal
              meeting={this.state.modalData}
              loading={this.state.modelLoading}
              shareData={this.state.shareData}
              closeModel={this.closeModel}
              modalShow={this.state.modalShow}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToPros = (state) => ({
  singleRecording: state.recording.singleRecording,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleRecodingIndex);
