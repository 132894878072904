import {
  SEND_CONTACT_DETAILS_START,
  SEND_CONTACT_DETAILS_SUCCESS,
  SEND_CONTACT_DETAILS_FAILURE,
} from "./ActionConstant";

export function sendContactDetailsStart(data) {
  return {
    type: SEND_CONTACT_DETAILS_START,
    data,
  };
}

export function sendContactDetailsSuccess(data) {
  return {
    type: SEND_CONTACT_DETAILS_SUCCESS,
    data,
  };
}

export function sendContactDetailsFailure(error) {
  return {
    type: SEND_CONTACT_DETAILS_FAILURE,
    error,
  };
}
