import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Logout from "../Auth/Logout";
import MainLayout from "../layouts/MainLayout";
import DashboardIndex from "../Dashboard/DashboardIndex";
import LoginIndex from "../Auth/LoginIndex";
import AuthLayout from "../layouts/AuthLayout";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import ResetPassword from "../Auth/ResetPassword";
import SubscriptionIndex from "../Subscriptions/SubscriptionIndex";
import CardsList from "../Accounts/Cards/CardsList";
import EditProfile from "../Accounts/Profile/EditProfile";
import DeleteAccountIndex from "../Accounts/DeleteAccount/DeleteAccountIndex";
import ChangePasswordIndex from "../Accounts/ChangePassword/ChangePasswordIndex";
import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import CreateMeetingIndex from "../Meeting/CreateMeetingIndex";
import SingleMeetingIndex from "../Meeting/SingleMeeting/SingleMeetingIndex";
import SubscriptionHistory from "../Subscriptions/SubscriptionsHistory";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import NotFoundIndex from "../NotFound/NotFoundIndex";
import NoDataFound from "../NoDataFound/NoDataFound";
import MeetingHistory from "../Meeting/History/MeetingHistory";
import SubscriptionInvoice from "../Subscriptions/SubscriptionsInvoice";
import EditMeetingIndex from "../Meeting/EditMeeting/EditMeetingIndex";
import RecordingListIndex from "../Recording/RecordingListIndex";
import JoinMeetingIndex from "../Meeting/JoinMeeting/JoinMeetingIndex";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import DashboardLoader from "../Loader/DashboardLoader";
import LandingPageLoader from "../Loader/LandingPageLoader";
import StaticIndex from "../StaticPage/StaticIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import SubscriptionNew from "../SubscriptionNew/SubscriptionNew";
import MyPlanNew from "../SubscriptionNew/MyPlanNew";
import SingleRecordingIndex from "../Recording/SingleRecording/SingleRecordingIndex";
import JitsiMeetingCreate from "../JitsiMeet/JitsiMeetingCreate";
import JitsiSampleApi from "../JitsiMeet/JitsiSampleApi";

import LiveChat from "react-livechat";

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
        
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
  }

  async fetchConfig() {
    const response = await fetch(apiConstants.settingsUrl);
    const configValue = await response.json();
    configuration.set({ configData: configValue.data }, { freeze: false });
    this.setState({ configLoading: false });
    $("#google_analytics").html(
      configuration.get("configData.google_analytics")
    );

    $("#header_scripts").html(configuration.get("configData.header_scripts"));

    $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        <div className="wrapper">
          {/* <DashboardLoader></DashboardLoader> */}
        </div>
      );
    }


    return (
      <>
          <LiveChat license="8256261"></LiveChat>

        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
          <meta
            name="description"
            content={configuration.get("configData.meta_description")}
          ></meta>
          <meta
            name="keywords"
            content={configuration.get("configData.meta_keywords")}
          ></meta>
          <meta
            name="author"
            content={configuration.get("configData.meta_author")}
          ></meta>
        </Helmet>

        <Switch>
          <AppRoute
            path={"/"}
            component={LandingPageIndex}
            exact
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dashboard"}
            component={DashboardIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/m/:meeting_unique_id"}
            component={JitsiMeetingCreate}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/jitsi-meet"}
            component={JitsiSampleApi}
            layout={MainLayout}
          />

          <AppRoute
            path={"/join/:meeting_unique_id"}
            component={JoinMeetingIndex}
            layout={
              localStorage.getItem("userLoginStatus") == "true"
                ? MainLayout
                : EmptyLayout
            }
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/meetings"}
            component={MeetingHistory}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/recordings"}
            component={RecordingListIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/recording/:id"}
            component={SingleRecordingIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/create-meeting"}
            component={CreateMeetingIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-meeting/:id"}
            component={EditMeetingIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/meeting/:id"}
            component={SingleMeetingIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={ProfileIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delete-account"}
            component={DeleteAccountIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePasswordIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscriptions"}
            component={SubscriptionIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscriptions-invoice/:id"}
            component={SubscriptionInvoice}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile-loader"}
            component={LandingPageLoader}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/my-subscriptions"}
            component={SubscriptionHistory}
            layout={MainLayout}
          />
          <Route path={"/login"} component={LoginIndex} />

          <Route path={"/signup"} component={RegisterIndex} />

          <Route path={"/forgot-password"} component={ForgotPassword} />

          <Route path={"/reset-password/:token"} component={ResetPassword} />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards"}
            component={CardsList}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/abc"}
            component={NotFoundIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscription-new"}
            component={SubscriptionNew}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/my-plan-new"}
            component={MyPlanNew}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={MainLayout}
          />

          <Route path={"/page/:title"} component={StaticIndex} />

          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
