import React, { useState } from "react";
import { connect } from "react-redux";
import { sendContactDetailsStart } from "../../store/actions/ContactFormAction";
const $ = window.$;

const ContactFormModal = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(
      sendContactDetailsStart({
        name: name,
        email,
        mobile,
        country,
        message,
      })
    );
    $("#verifyModalContent").modal("hide");
  };

  return (
    <div
      class="modal fade contact-sales-form"
      id="verifyModalContent"
      tabindex="-1"
      role="dialog"
      aria-labelledby="verifyModalContent"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="verifyModalContent_title">
              New message
            </h5>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={
                    window.location.origin + "/assets/images/contact-modal.png"
                  }
                  className="img-fluid contact-popup-img"
                />
              </div>
              <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <div class="wrap-input100">
                      <input
                        class="input100"
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={(event) => setName(event.target.value)}
                      />
                      <span class="focus-input100"></span>
                      <span class="symbol-input100">
                        <i class="fa fa-user" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                  <div class="wrap-input100">
                    <input
                      class="input100"
                      type="text"
                      name="email"
                      placeholder="Email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div class="wrap-input100">
                    <input
                      class="input100"
                      type="text"
                      name="mobile"
                      placeholder="Mobile Number"
                      onChange={(event) => setMobile(event.target.value)}
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div class="wrap-input100">
                    <input
                      class="input100"
                      type="text"
                      name="country"
                      placeholder="Country"
                      onChange={(event) => setCountry(event.target.value)}
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                      <i class="fa fa-globe" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div class="wrap-input100 validate-input">
                    <textarea
                      class="input100"
                      name="message"
                      placeholder="Message"
                      onChange={(event) => setMessage(event.target.value)}
                    ></textarea>
                    <span class="focus-input100"></span>
                  </div>
                  <div class="container-contact100-form-btn">
                    <button
                      class="contact100-form-btn"
                      disabled={props.contact.buttonDisable}
                    >
                      {props.contact.buttonLoadingContent != null
                        ? props.contact.buttonLoadingContent
                        : "Send"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  contact: state.contact.contactForm,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ContactFormModal);
