import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { joinMeetingStart } from "../../../store/actions/MeetingAction";

const JoinMeetingIndex = (props) => {
  const [username, setUsername] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonLoadingContent, setButtonLoadingContent] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("username") != null) {
      setUsername(localStorage.getItem("username"));
    }
  }, []);

  const joinMeeting = (event) => {
    setButtonDisable(true);
    setButtonLoadingContent("Loading... Please wait.");
    props.dispatch(
      joinMeetingStart({
        meeting_unique_id: props.match.params.meeting_unique_id,
        username: username,
      })
    );
  };

  return (
    <>
      <div className="main-content">
        <div className="join-meeting-sec mt-5">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div class="card">
                <div class="card-header purple-500 text-purple-500 p-4">
                  <div class="d-flex align-items-center">
                    <div class="card-title text-white mb-0">
                      Join meeting... Welcome {localStorage.getItem("username")}
                    </div>
                    <div class="ul-widget-app__bar ml-auto">
                      <i class="text-20 i-Smile"></i>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label class="ul-form__label" for="inputEmail4">
                          Full Name:
                        </label>
                        <input
                          class="form-control"
                          id="inputtext4"
                          type="text"
                          placeholder="Enter full name"
                          value={username}
                          onChange={(event) =>
                            setUsername(event.currentTarget.value)
                          }
                        />
                        <small
                          class="ul-form__text form-text"
                          id="passwordHelpBlock"
                        >
                          Please enter your full name
                        </small>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer">
                  <div class="mc-footer">
                    <div class="row">
                      <div class="col-lg-12 text-center">
                        <button
                          class="btn btn-primary m-1"
                          type="button"
                          onClick={joinMeeting}
                          disabled={props.joinMeeting.buttonDisable}
                        >
                          {props.joinMeeting.loadingButtonContent != null
                            ? props.joinMeeting.loadingButtonContent
                            : "Join Meeting"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  joinMeeting: state.meeting.joinMeeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(JoinMeetingIndex);
