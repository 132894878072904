import React, { Component } from "react";
import api from "../../Environment";
import renderHTML from "react-render-html";
import { connect } from "react-redux";
import { fetchSinglePageStart } from "../../store/actions/PageAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import AuthFooter from "../layouts/Footer/AuthFooter";

class StaticIndex extends Component {
  state = {
    pageData: null,
    loadingPageData: true,
    active: null,
    displayContent: null,
    currentPageTitle: null,
  };
  componentDidMount() {
    this.setState({ currentPageTitle: this.props.match.params.title });
    setTimeout(() => {
      this.props.dispatch(
        fetchSinglePageStart({ unique_id: this.props.match.params.title })
      );
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.title !== prevProps.match.params.title) {
      // call the fetch function again
      this.props.dispatch(
        fetchSinglePageStart({ unique_id: this.props.match.params.title })
      );
    }
  }

  getPageDetails = () => {
    let pageData = { unique_id: this.state.currentPageTitle };
    api.postMethod("pages_list", pageData).then((response) => {
      if (response.data.success) {
        this.setState({
          pageData: response.data.data,
          loadingPageData: false,
        });
        setTimeout(() => {
          // this.setState({
          //   displayContent: this.state.pageData,
          //   currentPageTitle: "",
          // });
        }, 1000);
      } else {
      }
    });
  };

  changePage = (event, page) => {
    event.preventDefault();
    this.setState({ displayContent: page });
  };
  render() {
    // const { displayContent } = this.state;

    const displayContent = this.props.page.pageData;

    return (
      <>
        <header className="header landing-sec">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-icon"></span>
                <span className="navbar-icon"></span>
                <span className="navbar-icon"></span>
              </button>
              <a className="navbar-brand" href="/">
                <img
                  alt={configuration.get("configData.site_name")}
                  src={configuration.get("configData.site_icon")}
                  className="logo"
                />
              </a>
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo01"
              >
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                  {localStorage.getItem("userLoginStatus") == "true" ? (
                    <li className="nav-item active">
                      <Link className="nav-link smooth" to={"/dashboard"}>
                        Dashboard
                      </Link>
                    </li>
                  ) : (
                    <>
                      <li className="nav-item active">
                        <Link className="nav-link smooth" to={"/login"}>
                          Login
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link smooth" to={"/signup"}>
                          Register
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </header>

        <div className="landing-main-wrapper">
          <div className="static-page-sec sm-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {displayContent.loading ? (
                    "Loading..."
                  ) : (
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">
                          {displayContent.data.title}
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="main-section">
                          {renderHTML(displayContent.data.description)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AuthFooter />
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  page: state.page,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticIndex);
