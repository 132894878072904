import React, { Component } from "react";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { format, isValid } from "date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  fetchSingleMeetingStart,
  editMeeting,
  updateMeetingStart,
} from "../../../store/actions/MeetingAction";

class EditMeetingIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      inputData: {
        schedule_time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        title: "My Meeting " + Math.floor(Math.random() * 10000) + 2,
        description: "Welcome to My Meeting",
        duration: 1,
      },
      selectedDate: new Date(),
    };
  }

  componentDidMount() {
    this.props.dispatch(
      fetchSingleMeetingStart({ meeting_unique_id: this.props.match.params.id })
    );
  }

  handleChange = ({ currentTarget: input }) => {
    this.props.dispatch(editMeeting(input.name, input.value));
  };

  handleChangeImage = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      if (input.name == "picture")
        this.setState({
          imagePreviewUrl: reader.result,
        });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleChangeDate = (date) => {
    if (isValid(date)) {
      const schedule_time = format(date, "yyyy-MM-dd HH:mm:ss");
      this.props.dispatch(editMeeting("schedule_time", schedule_time));
    }
  };

  handleDateChange = (date) => {
    if (isValid(date)) {
      const inputData = { ...this.state.inputData };
      inputData["schedule_time"] = format(date, "yyyy-MM-dd HH:mm:ss");
      this.setState({ inputData });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(updateMeetingStart(this.state.inputData));
  };

  render() {
    const { inputData, selectedDate } = this.state;
    const { singleMeeting } = this.props.meeting;
    return (
      <section className="widgets-content">
        <div className="row mt-4">
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="card">
              <div className="card-header bg-transparent">
                <h4>Edit Meeting</h4>
              </div>
              <div className="card-body">
                {singleMeeting.loading ? (
                  "Loading..."
                ) : (
                  <div className="row">
                    <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12">
                      <form onSubmit={this.handleSubmit} autoComplete="off">
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            htmlFor="inputEmail3"
                          >
                            <h6>Title</h6>
                          </label>
                          <div className="col-sm-12">
                            <input
                              className="form-control"
                              id="inputEmail3"
                              type="text"
                              value={singleMeeting.data.meeting_details.title}
                              name="title"
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label mb-0"
                            htmlFor="date-time"
                          >
                            <h6>Schedule Time (optinal)</h6>
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="col-sm-6">
                              <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date picker dialog"
                                format="MM/dd/yyyy"
                                value={
                                  singleMeeting.data.meeting_details
                                    .schedule_time
                                }
                                onChange={this.handleChangeDate}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </div>
                            <div className="col-sm-6">
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time picker"
                                value={
                                  singleMeeting.data.meeting_details
                                    .schedule_time
                                }
                                onChange={this.handleChangeDate}
                                KeyboardButtonProps={{
                                  "aria-label": "change time",
                                }}
                              />
                            </div>
                          </MuiPickersUtilsProvider>
                          {/* <div className="col-sm-4">
                            <label for="picker1">Duration</label>
                            <select
                              className="form-control duration-select"
                              name="duration"
                              onChange={this.handleChange}
                            >
                              <option value="1">1 hr</option>
                              <option value="2">2 hrs</option>
                              <option value="3">3 hrs</option>
                              <option value="4">4 hrs</option>
                              <option value="5">5 hrs</option>
                              <option value="6">6 hrs</option>
                              <option value="7">7 hrs</option>
                              <option value="8">8 hrs</option>
                              <option value="9">9 hrs</option>
                              <option value="10">10 hrs</option>
                            </select>
                          </div> */}
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            htmlFor="inputEmail3"
                          >
                            <h6>Upload Image</h6>
                          </label>
                          <div className="col-sm-12">
                            {/* <div className="upload-btn-wrapper create-meeting">
                            <button className="upload-btn">
                              Upload a image
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              name="picture"
                              onChange={this.handleChangeImage}
                            />
                        </div>*/}
                            <form
                              className="dropzone"
                              id="single-file-upload"
                              action="#"
                            >
                              <div className="fallback">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="picture"
                                  onChange={this.handleChangeImage}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            htmlFor="desc"
                          >
                            <h6>Description</h6>
                          </label>
                          <div className="col-sm-12">
                            <textarea
                              className="form-control"
                              id="desc"
                              type="text"
                              value={
                                singleMeeting.data.meeting_details.description
                              }
                              name="description"
                              rows="5"
                              onChange={this.handleChange}
                              autoComplete="off"
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <button
                              className="btn btn-block btn-primary"
                              type="submit"
                              disabled={this.props.meeting.buttonDisable}
                            >
                              {this.props.meeting.loadingButtonContent != null
                                ? this.props.meeting.loadingButtonContent
                                : "Edit Meeting"}
                            </button>
                          </div>
                          <div className="col-sm-6"></div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12">
                      <div className="meeting-info card">
                        <div className="card-header">
                          <h4>My Meeting</h4>
                        </div>
                        <div className="card-body">
                          <ul className="list-group meeting-list-sec">
                            <li className="list-group-item">
                              <h5>
                                {singleMeeting.data.meeting_details.title}
                              </h5>
                              <div className="meeting-info-details">
                                <p className="mr-4">
                                  <i className="fas fa-history mr-2"></i>{" "}
                                  {(
                                    singleMeeting.data.meeting_details
                                      .no_of_minutes / 60
                                  ).toFixed(2)}{" "}
                                  hrs
                                </p>
                                <p>
                                  <i className="far fa-calendar-alt mr-2"></i>{" "}
                                  {singleMeeting.data.meeting_details
                                    .schedule_time
                                    ? new Date(
                                        singleMeeting.data.meeting_details.schedule_time
                                      ).toLocaleString()
                                    : ""}
                                </p>
                              </div>
                              <p>
                                {singleMeeting.data.meeting_details.description}
                              </p>
                              <div className="meeting-link">
                                <p>
                                  <a href="javascript:void(0);">
                                    {window.location.origin}/join/
                                    {singleMeeting.data.meeting_details.title}
                                  </a>
                                </p>
                              </div>
                              <div className="share-later-sec">
                                <a href="#">
                                  <h5>
                                    <i className="far fa-share-square mr-2"></i>
                                    Later You Can Share
                                  </h5>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToPros = (state) => ({
  meeting: state.meeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditMeetingIndex);
