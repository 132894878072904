import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./SubscriptionNew.css";
class SubscriptionNew extends Component {
  render() {
    return (
      <>
        <div className="subscription-latest-sec">
          <div className="container">
            <div className="subscription-latest-list-sec">
              <h3 className="title-main">Upgrade Your Plan</h3>
              <p className="desc-main">
                You are currently on a Pro Plan. Select a plan below to Upgrade
                your Zoom account.
              </p>
              <div className="row no-gutters">
                <div className="col-lg-3 col-xl-3">
                  <Link to="#">
                    <div className="header-content">
                      <h6></h6>
                    </div>
                    <div className="price-box">
                      <div className="price-header">
                        <h4>Basic</h4>
                        <h6>Personal Meeting</h6>
                        <h2>Free</h2>
                        <p className="space-price"></p>
                        <button className="btn price-btn btn-block">
                          Basic
                        </button>
                      </div>
                      <div className="price-body">
                        <ul className="price-list-sec list-unstyled">
                          <li>
                            <span>Host up to 100 participants</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Unlimited 1 to 1 meetings</span>
                          </li>
                          <li>
                            <span>40 mins limit on group meetings</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Unlimited number of meetings</span>
                          </li>
                          <li>
                            <span>Ticket Support</span>
                          </li>
                          <li>
                            <span>
                              {" "}
                              <i class="fas fa-plus color-blue mr-2"></i>Video
                              Conferencing Features
                            </span>
                          </li>
                          <li>
                            <span>
                              <i class="fas fa-plus color-blue mr-2"></i>Web
                              Conferencing Features
                            </span>
                          </li>
                          <li>
                            <span>
                              <i class="fas fa-plus color-blue mr-2"></i>Group
                              Collaboration Features
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-xl-3">
                  <Link to="#">
                    <div className="header-content">
                      <h6></h6>
                    </div>
                    <div className="price-box">
                      <div className="price-header">
                        <h4>Pro</h4>
                        <h6>Great for Small Teams</h6>
                        <h2>
                          <span>
                            $14.99 <span className="sub-desc">/mo/host</span>
                          </span>
                          <span className="sub-desc-icon">
                            <i class="fas fa-info-circle"></i>
                          </span>
                        </h2>
                        <p>Purchase up to 9 hosts per account</p>
                        <button className="btn price-btn btn-block">
                          Current Plan
                        </button>
                      </div>
                      <div className="price-body">
                        <ul className="price-list-sec list-unstyled">
                          <li>
                            <span>
                              All Basic features{" "}
                              <i class="fas fa-plus ml-2"></i>
                            </span>
                          </li>
                          <li>
                            <span>
                              Includes 100 participants
                              <p className="desc-link">
                                Need more participants?
                              </p>
                            </span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Meeting duration limit is 24 hrs</span>
                          </li>
                          <li>
                            <span>User management</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Admin feature controls</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Reporting</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Custom Personal Meeting ID</span>
                          </li>
                          <li>
                            <span>Assign scheduler</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-xl-3">
                  <Link to="#">
                    <div className="header-content active">
                      <h6>Recommended Plan</h6>
                    </div>
                    <div className="price-box">
                      <div className="price-header">
                        <h4>Business</h4>
                        <h6>Small & Med Business</h6>
                        <h2>
                          <span>
                            $19.99 <span className="sub-desc">/mo/host</span>
                          </span>
                          <span className="sub-desc-icon">
                            <i class="fas fa-info-circle"></i>
                          </span>
                        </h2>
                        <p>Starting at 10 hosts for $199.9/mo</p>
                        <button className="btn price-btn btn-block active">
                          Upgrade to Business
                        </button>
                      </div>
                      <div className="price-body">
                        <ul className="price-list-sec list-unstyled">
                          <li>
                            <span>
                              All Basic features{" "}
                              <i class="fas fa-plus ml-2"></i>
                            </span>
                          </li>
                          <li>
                            <span>
                              Includes 100 participants
                              <p className="desc-link">
                                Need more participants?
                              </p>
                            </span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Meeting duration limit is 24 hrs</span>
                          </li>
                          <li>
                            <span>User management</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Admin feature controls</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Reporting</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Custom Personal Meeting ID</span>
                          </li>
                          <li>
                            <span>Assign scheduler</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-xl-3">
                  <Link to="#">
                    <div className="header-content">
                      <h6></h6>
                    </div>
                    <div className="price-box">
                      <div className="price-header">
                        <h4>Enterprise</h4>
                        <h6>Large Enterprise-Ready</h6>
                        <h2>
                          <span>
                            $14.99 <span className="sub-desc">/mo/host</span>
                          </span>
                          <span className="sub-desc-icon">
                            <i class="fas fa-info-circle"></i>
                          </span>
                        </h2>
                        <p>Starting at 100 hosts for $199.9/mo</p>
                        <button className="btn price-btn btn-block active-2">
                          Current Plan
                        </button>
                      </div>
                      <div className="price-body">
                        <ul className="price-list-sec list-unstyled">
                          <li>
                            <span>
                              All Basic features{" "}
                              <i class="fas fa-plus ml-2"></i>
                            </span>
                          </li>
                          <li>
                            <span>
                              Includes 100 participants
                              <p className="desc-link">
                                Need more participants?
                              </p>
                            </span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Meeting duration limit is 24 hrs</span>
                          </li>
                          <li>
                            <span>User management</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Admin feature controls</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Reporting</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                          <li>
                            <span>Custom Personal Meeting ID</span>
                          </li>
                          <li>
                            <span>Assign scheduler</span>
                            <i class="fas fa-info-circle color-grey"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SubscriptionNew;
