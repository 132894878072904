import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchMySubscriptionStart,
  disableSubscriptionAutoRenewalStart,
  enableSubscriptionAutoRenewalStart,
  fetchSubscriptionStart,
} from "../../store/actions/SubscriptionAction";
import MySubscriptionLoader from "../Loader/MySubscriptionLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

import SubscriptionsNewLoader from "../Loader/SubscriptionsNewLoader";
import configuration from "react-global-configuration";
import NoResultFound from "../NoResultFound/NoResultFound";

import "./SubscriptionsHistory.css";

const SubscriptionHistory = (props) => {
  useEffect(() => {
    if (props.subscriptions.subscription.loading)
      props.dispatch(fetchSubscriptionStart());
    if (props.subscriptions.mySubscription.loading)
      props.dispatch(fetchMySubscriptionStart());
  }, []);

  const mySubscriptions = props.subscriptions.mySubscription;

  const { subscription } = props.subscriptions;

  const cancelSubscription = (event) => {
    event.preventDefault();
    props.dispatch(disableSubscriptionAutoRenewalStart());
  };

  const enableSubscription = (event) => {
    event.preventDefault();
    props.dispatch(enableSubscriptionAutoRenewalStart());
  };

  const handleSubmit = (event, subscription) => {
    event.preventDefault();
    props.history.push(
      `/subscriptions-invoice/${subscription.subscription_id}`,
      subscription
    );
  };
  // sendSubscriptionHistory = (event) => {
  //   event.preventDefault();
  //   this.props.history.push("/my-subscriptions");
  // };

  return (
    <>
      <div className="my-plan-new-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link pl-0 active"
                    id="pills-plans-tab"
                    data-toggle="pill"
                    href="#pills-plans"
                    role="tab"
                    aria-controls="pills-plans"
                    aria-selected="true"
                  >
                    Plans
                  </a>
                </li>

                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pills-my-plans-tab"
                    data-toggle="pill"
                    href="#pills-my-plans"
                    role="tab"
                    aria-controls="pills-my-plans"
                    aria-selected="false"
                  >
                    My Plans
                  </a>
                </li>

                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pills-invoice-history-tab"
                    data-toggle="pill"
                    href="#pills-invoice-history"
                    role="tab"
                    aria-controls="pills-invoice-history"
                    aria-selected="false"
                  >
                    Invoice History
                  </a>
                </li>
                <li class="nav-item ml-auto">
                  <Link class="nav-link pr-0" to={`/page/help`}>
                    <span className="color-purple">Help</span>
                  </Link>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-plans"
                  role="tabpanel"
                  aria-labelledby="pills-plans-tab"
                >
                  <div className="subscription-latest-sec">
                    <div className="container">
                      {subscription.loading ? (
                        <SubscriptionsNewLoader />
                      ) : (
                        <div className="subscription-latest-list-sec">
                          <h3 className="title-main">Upgrade Your Plan</h3>
                          <p className="desc-main">
                            {subscription.data.current_subscription ? (
                              <span>
                                You are currently on a{" "}
                                {
                                  subscription.data.current_subscription
                                    .subscription_name
                                }
                                . Select a plan below to Upgrade your{" "}
                                {configuration.get("configData.site_name")}{" "}
                                account.
                              </span>
                            ) : (
                              <span>
                                You are currently not subscribed any plan.
                                Select a plan below to Upgrade your{" "}
                                {configuration.get("configData.site_name")}{" "}
                                account.
                              </span>
                            )}
                          </p>
                          <div className="row no-gutters">
                            {subscription.data.subscriptions.length > 0 ? (
                              subscription.data.subscriptions.map(
                                (value, index) => (
                                  <div className="col-lg-3 col-xl-3 resp-mrg-btm-xs">
                                    <>
                                      {value.is_popular ? (
                                        <div className="header-content active">
                                          <h6>Recommended Plan</h6>
                                        </div>
                                      ) : (
                                        <div className="header-content">
                                          <h6></h6>
                                        </div>
                                      )}
                                      <div className="price-box">
                                        <div className="price-header">
                                          <h4>{value.title}</h4>
                                          <h6 className="text-muted">
                                            {value.title}
                                          </h6>
                                          <h2>
                                            <span>
                                              {value.amount_formatted}
                                              <span className="sub-desc">
                                                /{value.plan_formatted}
                                              </span>
                                            </span>
                                          </h2>
                                          {value.is_current_subscription ? (
                                            <button
                                              className={
                                                value.is_popular
                                                  ? `btn price-btn btn-block active`
                                                  : `btn price-btn btn-block active-2`
                                              }
                                              disabled
                                            >
                                              Current Plan
                                            </button>
                                          ) : value.is_popular ? (
                                            <button
                                              className="btn price-btn btn-block active"
                                              onClick={(event) =>
                                                handleSubmit(event, value)
                                              }
                                            >
                                              Change Plan
                                            </button>
                                          ) : (
                                            <button
                                              className="btn price-btn btn-block active-2"
                                              onClick={(event) =>
                                                handleSubmit(event, value)
                                              }
                                            >
                                              Change Plan
                                            </button>
                                          )}
                                        </div>
                                        <div className="price-body">
                                          <ul className="price-list-sec list-unstyled">
                                            <li>
                                              <span>
                                                Host up to {value.no_of_users}{" "}
                                                participants
                                              </span>
                                              <i class="fas fa-info-circle color-grey"></i>
                                            </li>
                                            <li>
                                              <span>HD Video Conferencing</span>
                                            </li>
                                            <li>
                                              <span>
                                                Collaborative Whiteboard
                                              </span>
                                            </li>
                                            <li>
                                              <span>
                                                {value.no_of_minutes_formatted}{" "}
                                                limit on group meetings
                                              </span>
                                              <i class="fas fa-info-circle color-grey"></i>
                                            </li>
                                          </ul>
                                          <hr></hr>
                                          <p>{value.description}</p>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                )
                              )
                            ) : (
                              <NoResultFound />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-my-plans"
                  role="tabpanel"
                  aria-labelledby="pills-my-plans-tab"
                >
                  <h2>My Plans</h2>
                  <p className="text-muted">
                    Note: Cancellation will be effective at the end of your
                    current billing cycle. You can upgrade your account at
                    anytime.
                  </p>
                  <div className="row">
                    <div className="col-md-12">
                      {mySubscriptions.loading ? (
                        <MySubscriptionLoader />
                      ) : mySubscriptions.data.history.length > 0 ? (
                        <table class="table my-plans-new table-responsive-sm">
                          <thead>
                            <tr>
                              <th scope="col">Plan Name</th>
                              <th scope="col">Participants</th>
                              <th scope="col">Duration</th>
                              <th scope="col">Billing Period</th>
                              <th scope="col">Service Effective Date</th>
                              <th scope="col">Next Invoice Date</th>
                              <th scope="col">Next Invoice Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mySubscriptions.data.history.map(
                              (value, index) => (
                                <tr
                                  className={
                                    value.is_current_subscription
                                      ? "text-success"
                                      : ""
                                  }
                                >
                                  <td scope="row">{value.subscription_name}</td>
                                  <td>{value.no_of_users_formatted}</td>
                                  <td>{value.no_of_minutes_formatted}</td>
                                  <td>{value.plan_formatted}</td>
                                  <td>{value.paid_date}</td>
                                  <td>{value.expiry_date}</td>
                                  <td>
                                    {value.is_current_subscription &&
                                    value.subscription_amount > 0
                                      ? value.subscription_amount_formatted
                                      : "-"}
                                  </td>
                                  <td>
                                    {value.is_current_subscription
                                      ? "Active"
                                      : "Expired"}
                                  </td>
                                  <td className="color-orange">
                                    {value.cancel_btn_status == 1 ? (
                                      <a href="" onClick={cancelSubscription}>
                                        Cancel Subscription
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    {value.cancel_btn_status == 0 ? (
                                      <a href="" onClick={enableSubscription}>
                                        Enable Subscription
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="btn-footer-section">
                        {/* <Link
                          to={"/subscriptions"}
                          className="btn btn-bottom active"
                        >
                          Upgrade plan
                        </Link> */}
                        <Link to={"/cards"} className="btn btn-bottom">
                          Update Credit Card
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-invoice-history"
                  role="tabpanel"
                  aria-labelledby="pills-invoice-history-tab"
                >
                  <h2>Invoice History</h2>
                  <div className="row">
                    <div className="col-md-12">
                      {mySubscriptions.loading ? (
                        <MySubscriptionLoader />
                      ) : mySubscriptions.data.history.length > 0 ? (
                        <table class="table my-plans-new">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Plan</th>
                              <th scope="col">Payment ID</th>
                              <th scope="col">Paid Via</th>
                              <th scope="col">Period</th>
                              <th scope="col">Total</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mySubscriptions.data.history.map(
                              (value, index) => (
                                <tr>
                                  <td>{value.created_at}</td>
                                  <td scope="row">{value.subscription_name}</td>
                                  <td>{value.payment_id}</td>
                                  <td>{value.payment_mode}</td>
                                  <td>
                                    {value.paid_date} - {value.expiry_date}
                                  </td>
                                  <td>{value.subscription_amount_formatted}</td>
                                  <td>{value.status_formatted}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="btn-footer-section">
                        {/* <Link
                          to={"/subscriptions"}
                          className="btn btn-bottom active"
                        >
                          Upgrade plan
                        </Link> */}
                        <Link to={"/cards"} className="btn btn-bottom">
                          Update Credit Card
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subscriptions: state.subscriptions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionHistory);
