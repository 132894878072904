import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPasswordStart } from "../../store/actions/UserAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { Notify } from "react-redux-notify";

class ResetPassword extends Component {
  state = {
    inputData: {},
  };

  handleChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    inputData[input.name] = input.value;
    inputData['reset_token'] = this.props.match.params.token;
    this.setState({ inputData });
  };

  handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    this.props.dispatch(resetPasswordStart(this.state.inputData));
  };
  render() {
    const { inputData } = this.state;
    return (
      <>
        <Notify position="TopRight" />
        <div className="app-admin-wrap layout-sidebar-large">
          <div className="login-box">
            <div className="main-content">
              <div className="row">
                <div className="col-lg-8 col-md-9 col-sm-12 col-xs-12 mx-auto">
                  <div className="auth-content">
                    <div className="card o-hidden">
                      <div className="row">
                        <div
                          className="col-md-6 text-center forgot-password-img"
                          style={{
                            backgroundSize: "contain",
                            backgroundImage: `url(${window.location.origin}/assets/images/forgot-password.svg)`,
                          }}
                        ></div>
                        <Link to={"/"}>
                          <div class="before-logo">
                            <img
                              src={configuration.get("configData.site_icon")}
                              alt="forgot-password"
                            />
                          </div>
                        </Link>
                        <div className="col-md-6">
                          <div className="p-4">
                            <div className="card-title mb-3 text-center">
                              Reset Password
                            </div>
                            <p className="text-muted text-center">
                              {configuration.get("configData.tag_name")}
                            </p>
                            <form
                              onSubmit={this.handleForgotPasswordSubmit}
                              autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-md-12 form-group mb-3 align-center">
                                        <label for="password">New Password</label>
                                        <input
                                        className="form-control"
                                        id="password"
                                        type="password"
                                        placeholder="Enter your new password"
                                        name="password"
                                        value={inputData.password}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-md-12 form-group mb-3 align-center">
                                        <label for="password_confirmation">Confirm Password</label>
                                        <input
                                        className="form-control"
                                        id="password_confirmation"
                                        type="password"
                                        placeholder="Confirm Password"
                                        name="password_confirmation"
                                        value={inputData.password_confirmation}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button
                                    className="btn btn-lg btn-block btn-primary"
                                    type="submit"
                                    disabled={this.props.inputData.buttonDisable}
                                    >
                                    {this.props.inputData.loadingButtonContent !=
                                    null
                                        ? this.props.inputData.loadingButtonContent
                                        : "Submit"}
                                    </button>
                                </div>
                            </form>
                            <div className="row mt-3">
                              <div className="col-md-12 col-xs-12 col-sm-12 text-center">
                                <Link className="text-muted" to={"/login"}>
                                  <u>Already have an Account?</u>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({
  inputData: state.users,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ResetPassword);
