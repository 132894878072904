import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { connect } from "react-redux";
const $ = window.$;

const MeetingInfoModal = (props) => {
  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Meeting Link Copied!!"
    );
    props.dispatch(createNotification(notificationMessage));
    $("#meetingInfoModal").modal("hide");
  };

  return (
    <div
      className="modal fade"
      id="meetingInfoModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="MeetingInfoTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered copy-meeting-modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="MeetingInfoTitle">
              Copy join meeting message
            </h3>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {props.loading ? (
            "Loading..."
          ) : (
            <div className="modal-body">
              <p className="title text-muted">
                Share this info with people you want in the meeting
              </p>
              <div className="copy-link-modal-detail">
                <h6 className="font-weight-bold mb-modal">
                  Please join a meeting by {localStorage.getItem("username")}.
                </h6>
                <h6 className="font-weight-bold">
                  <span className="text-muted">Meeting ID</span>:{" "}
                  {props.meeting.meeting_unique_id}
                </h6>
                <h6 className="font-weight-bold">
                  <span className="text-muted">Title</span>:{" "}
                  {props.meeting.title}
                </h6>
                <h6 className="font-weight-bold mb-modal">
                  <span className="text-muted">Scheduled</span>:{" "}
                  {props.meeting.schedule_time}
                </h6>

                <p className="font-weight-bold">
                  <span className="text-muted">Join Link</span>
                </p>
                <h6 className="font-weight-bold mb-modal">
                  {window.location.origin +
                    "/join/" +
                    props.meeting.meeting_unique_id}
                </h6>
              </div>
            </div>
          )}
          <div className="modal-footer">
            <CopyToClipboard text={props.shareData} onCopy={onCopy}>
              <button className="btn btn-primary btn-block" type="button">
                Click To Copy
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(MeetingInfoModal);
