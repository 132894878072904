export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const DISPLAY_FORGOT_PASSWORD = "DISPLAY_FORGOT_PASSWORD";
export const DISPLAY_LOGIN = "DISPLAY_LOGIN";
export const DISPLAY_SIGNUP = "DISPLAY_SIGNUP";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_START = "SUBSCRIPTION_PAYMENT_START";
export const SUBSCRIPTION_PAYMENT_SUCCESS = "SUBSCRIPTION_PAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_FAILURE = "SUBSCRIPTION_PAYMENT_FAILURE";

export const ENABLE_SUBSCRIPTION_AUTORENEWAL_START =
  "ENABLE_SUBSCRIPTION_AUTORENEWAL_START";
export const ENABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS =
  "ENABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS";
export const ENABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE =
  "ENABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE";

export const DISABLE_SUBSCRIPTION_AUTORENEWAL_START =
  "DISABLE_SUBSCRIPTION_AUTORENEWAL_START";
export const DISABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS =
  "DISABLE_SUBSCRIPTION_AUTORENEWAL_SUCCESS";
export const DISABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE =
  "DISABLE_SUBSCRIPTION_AUTORENEWAL_FAILURE";

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

// Meeting

export const FETCH_MEETING_START = "FETCH_MEETING_START";
export const FETCH_MEETING_SUCCESS = "FETCH_MEETING_SUCCESS";
export const FETCH_MEETING_FAILURE = "FETCH_MEETING_FAILURE";

export const ADD_MEETING_START = "ADD_MEETING_START";
export const ADD_MEETING_SUCCESS = "ADD_MEETING_SUCCESS";
export const ADD_MEETING_FAILURE = "ADD_MEETING_FAILURE";

export const EDIT_MEETING = "EDIT_MEETING";

export const UPDATE_MEETING_START = "UPDATE_MEETING_START";
export const UPDATE_MEETING_SUCCESS = "UPDATE_MEETING_SUCCESS";
export const UPDATE_MEETING_FAILURE = "UPDATE_MEETING_FAILURE";

export const DELETE_MEETING_START = "DELETE_MEETING_START";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_FAILURE = "DELETE_MEETING_FAILURE";

export const FETCH_SINGLE_MEETING_START = "FETCH_SINGLE_MEETING_START";
export const FETCH_SINGLE_MEETING_SUCCESS = "FETCH_SINGLE_MEETING_SUCCESS";
export const FETCH_SINGLE_MEETING_FAILURE = "FETCH_SINGLE_MEETING_FAILURE";

export const SEARCH_MEETING_START = "SEARCH_MEETING_START";
export const SEARCH_MEETING_SUCCESS = "SEARCH_MEETING_SUCCESS";
export const SEARCH_MEETING_FAILURE = "SEARCH_MEETING_FAILURE";

export const FETCH_UPCOMING_MEETING_START = "FETCH_UPCOMING_MEETING_START";
export const FETCH_UPCOMING_MEETING_SUCCESS = "FETCH_UPCOMING_MEETING_SUCCESS";
export const FETCH_UPCOMING_MEETING_FAILURE = "FETCH_UPCOMING_MEETING_FAILURE";

export const JOIN_MEETING_START = "JOIN_MEETING_START";
export const JOIN_MEETING_SUCCESS = "JOIN_MEETING_SUCCESS";
export const JOIN_MEETING_FAILURE = "JOIN_MEETING_FAILURE";

export const START_MEETING_START = "START_MEETING_START";
export const START_MEETING_SUCCESS = "START_MEETING_SUCCESS";
export const START_MEETING_FAILURE = "START_MEETING_FAILURE";

export const END_MEETING_JITSI_START = "END_MEETING_JITSI_START";
export const END_MEETING_JITSI_SUCCESS = "END_MEETING_JITSI_SUCCESS";
export const END_MEETING_JITSI_FAILURE = "END_MEETING_JITSI_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Pages

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Recording

export const FETCH_RECORDING_START = "FETCH_RECORDING_START";
export const FETCH_RECORDING_SUCCESS = "FETCH_RECORDING_SUCCESS";
export const FETCH_RECORDING_FAILURE = "FETCH_RECORDING_FAILURE";

export const FETCH_SINGLE_RECORDING_START = "FETCH_SINGLE_RECORDING_START";
export const FETCH_SINGLE_RECORDING_SUCCESS = "FETCH_SINGLE_RECORDING_SUCCESS";
export const FETCH_SINGLE_RECORDING_FAILURE = "FETCH_SINGLE_RECORDING_FAILURE";

export const SEARCH_RECORDING_START = "SEARCH_RECORDING_START";
export const SEARCH_RECORDING_SUCCESS = "SEARCH_RECORDING_SUCCESS";
export const SEARCH_RECORDING_FAILURE = "SEARCH_RECORDING_FAILURE";

export const DELETE_RECORDING_START = "DELETE_RECORDING_START";
export const DELETE_RECORDING_SUCCESS = "DELETE_RECORDING_SUCCESS";
export const DELETE_RECORDING_FAILURE = "DELETE_RECORDING_FAILURE";

// Dashboard API

export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

// Contact form Details

export const SEND_CONTACT_DETAILS_START = "SEND_CONTACT_DETAILS_START";
export const SEND_CONTACT_DETAILS_SUCCESS = "SEND_CONTACT_DETAILS_SUCCESS";
export const SEND_CONTACT_DETAILS_FAILURE = "SEND_CONTACT_DETAILS_FAILURE";

// Reset Password
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
