import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchMeetingStart,
  deleteMeetingStart,
} from "../../../store/actions/MeetingAction";
import AddToCalendar from "react-add-to-calendar";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import MeetingInfoModal from "../MeetingInfoModal";
import MeetingsLoader from "../../Loader/MeetingsLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";

let icon = { "calendar-plus-o": "left" };

class MeetingHistory extends Component {
  state = {
    event: {
      title: "Sample Event",
      description: "This is the sample event provided as an example only",
      location: "Portland, OR",
      startTime: "2016-09-16T20:15:00-04:00",
      endTime: "2016-09-16T21:45:00-04:00",
    },
    modalData: {},
    modelLoading: true,
    shareData: "",
    modalShow: false,
  };

  componentDidMount() {
    if (this.props.meeting.meeting.loading)
      this.props.dispatch(fetchMeetingStart());
  }

  deleteMeeting = (event, meeting) => {
    event.preventDefault();
    this.props.dispatch(deleteMeetingStart({ meeting_id: meeting.meeting_id }));
  };

  changeModelData = (event, meeting) => {
    this.setState({
      modelLoading: false,
      modalData: meeting,
      shareData: `Meeting ID: ${meeting.meeting_unique_id}\nTitle: ${meeting.title}\nDescription: ${meeting.description}\nScheduled: ${meeting.schedule_time}\nMeeting Link: ${window.location.origin}/join/${meeting.meeting_unique_id}`,
      modalShow: true,
    });
  };

  closeModel = (event) => {
    this.setState({ modelShow: false });
  };

  render() {
    const { meeting } = this.props.meeting;
    return (
      <section className="widgets-content">
        <div className="row mt-4">
          <div className="col-xl-12 col-md-12 mb-4">
            <div className="card">
              <div className="card-header flex-content bg-transparent">
                <h4>Meetings</h4>
                <Link
                  className="btn btn-primary width-200 btn-lg m-1"
                  to={"/create-meeting"}
                >
                  <i className="fas fa-plus mr-2"></i>Schedule Meeting
                </Link>
              </div>
              <div className="card-body">
                {meeting.loading ? (
                  <MeetingsLoader />
                ) : meeting.data.data.meetings.length > 0 ? (
                  meeting.data.data.meetings.map((meeting) => (
                    <div className="ul-widget__body">
                      <div className="ul-widget5 md-device-scroll">
                        <div className="ul-widget5__item section-display">
                          <div className="ul-widget5__content">
                            <div className="ul-widget5__pic">
                              <img src={meeting.picture} alt="Third slide" />
                            </div>
                            <div className="ul-widget5__section resp-padding-top ">
                              <Link
                                className="ul-widget4__title"
                                to={`/meeting/${meeting.meeting_unique_id}`}
                                target="_blank"
                              >
                                {meeting.title}
                              </Link>
                              <p className="ul-widget5__desc font-8">
                                <span>Scheduled: </span>
                                <span className="">
                                  {meeting.schedule_time}
                                </span>
                              </p>
                              <div className="ul-widget5__info">
                                <span>Allowed: </span>
                                <span className="text-primary">
                                  {" "}
                                  {meeting.no_of_users_formatted}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="ul-widget5__content">
                            <div className="ul-widget5__section">
                              <AddToCalendar
                                event={{
                                  title: meeting.title,
                                  description: meeting.description,
                                  startTime: meeting.schedule_time,
                                  location: "",
                                }}
                                buttonTemplate={icon}
                              />
                            </div>
                          </div>
                          <div className="ul-widget5__content">
                            <div className="ul-widget5__section">
                              <a className="ul-widget4__title" href="#">
                                <span>{meeting.status_formatted}</span>
                              </a>
                              <br />
                              <button
                                className="ul-widget5__desc green-link"
                                data-toggle="modal"
                                data-target="#meetingInfoModal"
                                onClick={(event) =>
                                  this.changeModelData(event, meeting)
                                }
                              >
                                <i className="far fa-copy mr-2"></i>Copy to
                                clipboard
                              </button>
                            </div>
                          </div>
                          <div className="ul-widget5__content">
                            <button
                              className="btn bg-white _r_btn border-0"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img
                                className="action-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/list.svg"
                                }
                                alt="List"
                              />
                            </button>
                            <div
                              className="dropdown-menu"
                              x-placement="bottom-start"
                              style={{
                                position: "absolute",
                                transform: "translate3d(0px, 33px, 0px)",
                                top: "0px",
                                left: "0px",
                                willChange: "transform",
                              }}
                            >
                              {meeting.is_owner == 1 ? (
                                <>
                                  {meeting.edit_btn_status == 1 ? (
                                    <Link
                                      className="dropdown-item"
                                      to={`/edit-meeting/${meeting.meeting_unique_id}`}
                                    >
                                      Edit
                                    </Link>
                                  ) : null}
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(event) =>
                                      this.deleteMeeting(event, meeting)
                                    }
                                  >
                                    Delete
                                  </a>
                                </>
                              ) : (
                                ""
                              )}
                              <Link
                                className="dropdown-item"
                                to={`/meeting/${meeting.meeting_unique_id}`}
                                target="_blank"
                              >
                                View
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </div>
        </div>

        <MeetingInfoModal
          meeting={this.state.modalData}
          loading={this.state.modelLoading}
          shareData={this.state.shareData}
          closeModel={this.closeModel}
          modalShow={this.state.modalShow}
        />
      </section>
    );
  }
}

const mapStateToPros = (state) => ({
  meeting: state.meeting,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MeetingHistory);
