import React from "react";

const SingleMeetingUserList = ({ userDisplay }) => {
  return (
    <div
      className="col-md-12 col-lg-4 col-xl-4 user-list"
      style={{ display: userDisplay }}
    >
      <div className="card mb-3">
        <div className="card-header bg-transparent font-weight-bold">
          <h4>Users</h4>
        </div>
        <div className="card-body">
          <div className="ul-widget-app__recent-messages">
            <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/1.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Jhon Wick</h6>
                </div>
              </div>
            </div>
            <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/3.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Jhon Cena</h6>
                </div>
              </div>
            </div>
            <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/4.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Jhon Cena</h6>
                </div>
                ={" "}
              </div>
            </div>
            <div className="ul-widget-app__row-comments border-bottom-gray-200 mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/2.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Jhon Cena</h6>
                </div>
              </div>
            </div>
            <div className="ul-widget-app__row-comments mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/5.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Chriss Patt</h6>
                </div>
              </div>
            </div>
            <div className="ul-widget-app__row-comments mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/2.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Chriss Patt</h6>
                </div>
              </div>
            </div>
            <div className="ul-widget-app__row-comments mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/1.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Chriss Patt</h6>
                </div>
              </div>
            </div>
            <div className="ul-widget-app__row-comments mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/1.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Chriss Patt</h6>
                </div>
              </div>
            </div>
            <div className="ul-widget-app__row-comments mb-0">
              <div className="ul-widget-app__profile-pic mr-3">
                <img
                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                  src={window.location.origin + "/assets/images/faces/1.jpg"}
                  alt="alt"
                />
              </div>
              <div className="ul-widget-app__comment">
                <div className="ul-widget-app__profile-title">
                  <h6 className="heading">Chriss Patt</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMeetingUserList;
