import React, { useState, useEffect } from "react";
import ProgressComponent from "@material-ui/core/CircularProgress";

function JitsiSampleApi() {
  const [loading, setLoading] = useState(false);
  const containerStyle = {
    width: "800px",
    height: "100vh",
  };

  const jitsiContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  function startConference() {
    try {
      const domain = "jitsi-streaming.startstreaming.co";
      const options = {
        roomName: "My-Meeting-" + Math.floor(Math.random() * 10000) + 2,
        height: 400,
        parentNode: document.getElementById("jitsi-container"),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
        },
        configOverwrite: {
          disableSimulcast: false,
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      api.addEventListener("videoConferenceJoined", () => {
        console.log("Local User Joined");
        setLoading(false);
        api.executeCommand("displayName", "MyName");
      });
      api.addEventListener("readyToClose", () => {
        window.location.href = window.location.origin + "/meetings";
      });
    } catch (error) {
      console.error("Failed to load Jitsi API", error);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else alert("Jitsi Meet API script not loaded");
  }, []);

  return (
    <div style={containerStyle}>
      {loading && <ProgressComponent />}
      <div id="jitsi-container" style={jitsiContainerStyle} />

      {/* <iframe
        allow="camera; microphone; fullscreen; display-capture"
        src="https://jitsi-streaming.startstreaming.co/My-Meeting-35822"
        style={{ height: "100%", width: "100%", border: "0px" }}
      ></iframe> */}
    </div>
  );
}

export default JitsiSampleApi;
