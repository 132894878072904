import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  accessibility: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

class LandingPageIndex extends Component {
  state = {};
  render() {
    return (
      <>
        <section className="sm-padding features-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12  text-center">
                <h1 className="section-title">Features</h1>
              </div>
            </div>
            <div className="row flex-row-reverse">
              <div className="col-lg-9 col-md-12 col-sm-12">
                <div className="row mb-4">
                  <div className="col-lg-3 col-md-3 col-sm-12 resp-mrg-btm-sm">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/chat.png"
                        }
                        alt="Chat"
                      />
                      <h5 className="title">Chat</h5>
                      <p className="px-2 desc">
                        Send public and private messages
                      </p>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 resp-mrg-btm-sm ">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/video.png"
                        }
                        alt="Video"
                      />
                      <h5 className="title">Video</h5>
                      <p className="px-2 desc">
                        1-on-1 or multiple video attendees
                      </p>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 resp-mrg-btm-sm ">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/mic.png"
                        }
                        alt="Audio"
                      />
                      <h5 className="title">Audio</h5>
                      <p className="px-2 desc">
                        Audio only attendees or entire meetings
                      </p>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/screen.png"
                        }
                        alt="Screen Sharing"
                      />
                      <h5 className="title">Screen Sharing</h5>
                      <p className="px-2 desc">
                        Share your screen with attendees
                      </p>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 resp-mrg-btm-sm ">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/cloudupload.png"
                        }
                        alt="Record"
                      />
                      <h5 className="title">Record</h5>
                      <p className="px-2 desc">Record your meetings in cloud</p>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 resp-mrg-btm-sm ">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/whiteboard2.png"
                        }
                        alt="Whiteboard"
                      />
                      <h5 className="title">Whiteboard</h5>
                      <p className="px-2 desc">
                        Add presentations, annotate or draw
                      </p>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 resp-mrg-btm-sm ">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/collab.png"
                        }
                        alt="Notes"
                      />
                      <h5 className="title">Collaborative Notes</h5>
                      <p className="px-2 desc">
                        Realtime multi user notes together
                      </p>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <a href="#" className="features-box">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/poll.png"
                        }
                        alt="Polls"
                      />
                      <h5 className="title">Polls</h5>
                      <p className="px-2 desc">
                        Quick opinion polls from attendees
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding business-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className=" business-info">
                  <h3 className="title">
                    Business-grade Virtual Conference Halls
                  </h3>
                  <p className="desc">
                    Conduct professional-level highly interactive business
                    conferences with people around the world using {configuration.get("configData.site_name")}
                  </p>
                  <div className="row">
                    <div className="col-md-2 resp-mrg-btm-sm">
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/landing-page/calendar.png"
                        }
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-10">
                      <h4 className="sub-title">Schedule Meetings</h4>
                      <p className="desc">
                        {configuration.get("configData.site_name")} lets you keep check of your important meetings
                        and engagements right inside your business’s ecosystem.
                        Schedule meetings and sync the data with any of your
                        organizer apps with this robust video conferencing
                        solution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <img
                  src={
                    window.location.origin +
                    "/assets/images/landing-page/banner-img-1.png"
                  }
                />
              </div>
            </div>
          </div>
        </section>
        {/*<section className="testimonial-sec sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12  text-center">
                <h1 className="section-title">Testimonial</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="testimonial-card">
                  <div className="testimonial-list">
                    <Slider {...settings}>
                      <div className="testimonial-item">
                        <div className="inner">
                          <div className="testimonial-box">
                            <img
                              src={
                                window.location.origin +
                                "/assets/images/landing-page/2.jpg"
                              }
                              className="client-img"
                            />
                            <div className="details">
                              <h4 className="client-name">Angel Witicker</h4>
                              <h5 className="client-role">
                                Head of Marketing, Extra Space
                              </h5>
                              <p className="sub-desc">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-item">
                        <div className="inner">
                          <div className="testimonial-box">
                            <img
                              src={
                                window.location.origin +
                                "/assets/images/landing-page/9.jpg"
                              }
                              className="client-img"
                            />
                            <div className="details">
                              <h4 className="client-name">Debra Becker</h4>
                              <h5 className="client-role">
                                Founder & CEO, Divi Corner
                              </h5>
                              <p className="sub-desc">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-item">
                        <div className="inner">
                          <div className="testimonial-box">
                            <img
                              src={
                                window.location.origin +
                                "/assets/images/landing-page/10.jpg"
                              }
                              className="client-img"
                            />
                            <div className="details">
                              <h4 className="client-name">John Wise</h4>
                              <h5 className="client-role">
                                Head of Design, Elegant Themes
                              </h5>
                              <p className="sub-desc">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-item">
                        <div className="inner">
                          <div className="testimonial-box">
                            <img
                              src={
                                window.location.origin +
                                "/assets/images/landing-page/13.jpg"
                              }
                              className="client-img"
                            />
                            <div className="details">
                              <h4 className="client-name">Angel Witicker</h4>
                              <h5 className="client-role">
                                Head of Marketing, Extra Space
                              </h5>
                              <p className="sub-desc">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
        <section className="sm-padding call-to-action">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="call-to-action-info">
                  <h3 className="title">
                    Host your own video conferencing suite and never fear outage
                  </h3>
                  <p className="desc">
                    We know you value privacy enough to not let your sensitive
                    company conference data exposed in another company's server.
                  </p>
                  <div className="double-btn-sec">
                    <Link to={"/signup"}>
                      <button className="btn btn-primary width-200 mr-2 resp-mr-2">
                        Get Started Now
                      </button>
                    </Link>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#verifyModalContent"
                    >
                      <button className="btn btn-outline-secondary width-200  m-1">
                        Book a Demo
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingPageIndex;
