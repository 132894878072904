import React from "react";

const NoResultFound = () => {
  return (
    <>
      <div className="no-data-found-sec">
        <div className="row">
          <div className="col-md-12">
            <div className="no-data-found-img-sec">
              <img
                alt="no-data-found"
                src={
                  window.location.origin + "/assets/images/no-result-found.svg"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoResultFound;
