import React from "react"
import ContentLoader from "react-content-loader"

const MySubscriptionLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={700}
    viewBox="0 0 1200 700"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="203" y="37" rx="0" ry="0" width="0" height="6" /> 
    <rect x="31" y="58" rx="2" ry="2" width="1100" height="57" /> 
    <rect x="30" y="60" rx="0" ry="0" width="10" height="474" /> 
    <rect x="1122" y="60" rx="0" ry="0" width="10" height="474" /> 
    <rect x="30" y="525" rx="0" ry="0" width="1100" height="10" /> 
    <rect x="62" y="157" rx="0" ry="0" width="150" height="30" /> 
    <rect x="62" y="200" rx="12" ry="12" width="90" height="22" /> 
    <rect x="330" y="158" rx="0" ry="0" width="111" height="14" /> 
    <rect x="330" y="184" rx="0" ry="0" width="102" height="8" /> 
    <rect x="330" y="203" rx="0" ry="0" width="96" height="10" /> 
    <rect x="600" y="163" rx="5" ry="5" width="200" height="32" /> 
    <rect x="980" y="165" rx="0" ry="0" width="85" height="14" /> 
    <rect x="950" y="191" rx="12" ry="12" width="150" height="22" /> 
    <rect x="62" y="243" rx="0" ry="0" width="1040" height="5" /> 
    <rect x="62" y="275" rx="0" ry="0" width="150" height="30" /> 
    <rect x="62" y="318" rx="12" ry="12" width="90" height="22" /> 
    <rect x="330" y="276" rx="0" ry="0" width="111" height="14" /> 
    <rect x="330" y="302" rx="0" ry="0" width="102" height="8" /> 
    <rect x="330" y="321" rx="0" ry="0" width="96" height="10" /> 
    <rect x="600" y="281" rx="5" ry="5" width="200" height="32" /> 
    <rect x="980" y="283" rx="0" ry="0" width="85" height="14" /> 
    <rect x="950" y="309" rx="12" ry="12" width="150" height="22" /> 
    <rect x="62" y="361" rx="0" ry="0" width="1040" height="5" /> 
    <rect x="62" y="395" rx="0" ry="0" width="150" height="30" /> 
    <rect x="62" y="438" rx="12" ry="12" width="90" height="22" /> 
    <rect x="330" y="396" rx="0" ry="0" width="111" height="14" /> 
    <rect x="330" y="422" rx="0" ry="0" width="102" height="8" /> 
    <rect x="330" y="441" rx="0" ry="0" width="96" height="10" /> 
    <rect x="600" y="401" rx="5" ry="5" width="200" height="32" /> 
    <rect x="980" y="403" rx="0" ry="0" width="85" height="14" /> 
    <rect x="950" y="429" rx="12" ry="12" width="150" height="22" /> 
    <rect x="64" y="481" rx="0" ry="0" width="1040" height="5" /> 
    <rect x="460" y="548" rx="5" ry="5" width="234" height="37" />
  </ContentLoader>
)

export default MySubscriptionLoader;